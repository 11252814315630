import { graphql } from 'gatsby';
import React, { FC, useState } from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import styles from './maison-mosaic.module.scss';
import Back from '../images/MaisonHeaderWebsite.png';
import PortfolioPopUpp from '../components/custom/portfolio-pop-upp/PortfolioPopUpp';
import { Album } from '../components/portfolio/interfaces';
import ImageWithThumbnailCard from '../components/custom/ImageWithThumbnailCard/ImageWithThumbnailCard';

const sizeInfo = [
  {
    title: 'SIZE',
    data: ['4"x 4"', '2"x 2"'],
    dataTwo: ['2"x 6"'],
    dataThree: ['Mosaic Bahia 12"x 12"'],
  },
  {
    title: 'THICKNESS',
    data: ['1/2"-5/8"'],
    dataTwo: ['5/8"'],
    dataThree: ['Approximately 1/2"'],
  },
];

const techInfo = [
  { title: 'FLOOR', data: ['Yes*'] },
  { title: 'WALL', data: ['Yes'] },
  { title: 'FROST RESISTANT', data: ['NO'] },
  { title: 'INDOOR', data: ['Yes'] },
  { title: 'OUTDOOR', data: ['Yes'] },
  { title: 'WET AREA', data: ['Yes'] },
  { title: 'VARIATION', data: ['HIGH'] },
];

export const query = graphql`
  {
    strapi {
      maisonMosaics {
        name
        id
        images {
          url
          caption
          alternativeText
        }
        imageSchema {
          url
          alternativeText
        }
        tearSheet {
          url
        }
        finish
        sortIndex
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      maisonMosaics: [
        {
          images: [
            {
              url: string;
              alternativeText: string;
              caption: string;
            },
          ];
          name: string;
          id: number;
          sortIndex: string | number;
          imageSchema: {
            url: string;
            alternativeText: string;
          };
          tearSheet: {
            url: string;
          };
          finish: string;
        },
      ];
    };
  };
}

const MorrocanTile: FC<Props> = ({
  data: {
    strapi: { maisonMosaics },
  },
}: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  // const setShowPopupToFalse = () => {
  //   setShowPopup(false);
  // };

  const albums: Album[] = maisonMosaics?.map((item, index) => ({
    Preview: {
      caption: item.images[0].caption,
      url: item.images[0].url,
    },
    id: item.id,
    index,
    gallery: {
      SortIndex: 0,
      name: item.name,
    },
  }));

  const [baseAlbum, setBaseAlbum] = useState(albums);
  const [chosenPdfUrl, setChosenPdfUrl] = useState(maisonMosaics[0].tearSheet.url);

  const togglePopUpp = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const targetIndex = Number(target.dataset.index);
    setBaseAlbum(
      maisonMosaics[targetIndex].images.map((item, index) => ({
        Preview: {
          caption: item.caption,
          url: item.url,
        },
        id: maisonMosaics[targetIndex].id,
        index,
        gallery: {
          SortIndex: maisonMosaics[targetIndex].sortIndex,
          name: maisonMosaics[targetIndex].name,
        },
      })),
    );

    setShowPopup(!showPopup);
    setChosenPdfUrl(maisonMosaics[targetIndex].tearSheet.url);
  };

  return (
    <Layout isWhiteBackground={true}>
      <SEO title="Maison Mosaic" />
      <section className={styles.wrapper}>
        <div className={styles.header}>
          <img src={Back} className={styles.image} />
          <div className={styles.border}></div>
          <div className={styles.titleContainer}>
            <p className={styles.title}>{'Maison Mosaic \n by Da Vinci'}</p>
            <p className={styles.text}>STOCKED</p>
          </div>
        </div>

        <section className={styles.tilesWrapper}>
          {/* <p className={styles.collectionName}>ZELLIGE</p> */}

          <div className={styles.tilesContainer}>
            {maisonMosaics?.map((item, i) => (
              <div
                key={i + `${item.name}`}
                className={styles.tile}
                style={{ order: item.sortIndex as number }}
              >
                <ImageWithThumbnailCard
                  image={item.imageSchema}
                  name={item.name}
                  onClick={togglePopUpp}
                  imgIndex={i}
                  smallImg={item.images[0].url}
                  finish={item.finish}
                />
              </div>
            ))}
          </div>

          {showPopup && (
            <PortfolioPopUpp
              descrioptionPdfUrl={chosenPdfUrl}
              selectedIndexOfGallery={0}
              showPopup={showPopup}
              albums={baseAlbum}
              setShowPopupToFalse={() => setShowPopup(false)}
            />
          )}

          {/* <p className={styles.collectionName}>BEJMAT</p> */}

          {/* <div className={styles.tilesContainer}>
            {morrocanBejmatTiles?.map((item, i) => (
              <div key={i + `${item.name}`} className={styles.tile}>
                <BejmatTile image={item.image} name={item.name} sizes={item.sizes} />
              </div>
            ))}
          </div> */}

          {/* {morrocanMosaicBahias.length && <p className={styles.collectionName}>MOSAIC BAHIA</p>}

          <div className={styles.tilesContainer}>
            {morrocanMosaicBahias?.map((item, i) => (
              <div key={i + `${item.name}`} className={styles.tile}>
                <BejmatTile image={item.image} name={item.name} sizes={item.sizes} />
              </div>
            ))}
          </div> */}
        </section>

        <section className={styles.infoWrapper}>
          <div className={styles.information}>
            {/* <img src={`${img5}`} alt="image" className={styles.infoImage} /> */}
            <div className={styles.sizeTechInformation}>
              <p className={styles.title}>General Substrate Requirements</p>
              <p className={styles.sizeText}>
                All substrates must be flat, smooth and free of all surface contaminants that could
                act as a bond breaker.{' '}
              </p>
            </div>
            <div className={styles.sizeTechInformation}>
              <p className={styles.title}>General Dry-Lay Directions</p>
              <p className={styles.sizeText}>
                A dry-lay of all mosaic sheets purchased is recommended prior to installation.
                Selecting from different boxes each time is advised to get a varied look.
              </p>
            </div>
            <div className={styles.sizeTechInformation}>
              <p className={styles.title}>Cutting & Drilling Stone Mosaics</p>
              <p className={styles.sizeText}>
                For all stone mosaics, use a diamond wet saw blade meant for cutting natural stone.
              </p>
            </div>
            <div className={styles.sizeTechInformation}>
              <p className={styles.title}>Moisture Sensitive Stone</p>
              <p className={styles.sizeText}>
                Prior to installation, be sure to determine if the natural stone being installed is
                prone to staining, discoloring, curling or warping. A Grout Release product is
                recommended if using a grout that could discolor the stone.
              </p>
            </div>
            <div className={styles.sizeTechInformation}>
              <p className={styles.title}>Sealing</p>
              <p className={styles.sizeText}>
                Using a soft, clean cloth, apply a penetrating natural stone sealer. For wet areas,
                two coats of sealer is recommended. When applying sealer to mixed medium mosaics
                that combines stone with other materials, thoroughly wipe off excess from non-stone
                materials to avoid build up. Always follow the sealer manufacturer’s guidelines.
              </p>
            </div>
            <div className={styles.sizeTechInformation}>
              <p className={styles.title}>Care & Maintenance</p>
              <p className={styles.sizeText}>
                For day-to-day cleaning, apply a pH neutral cleaner with a soft cloth. Remove the
                residue with a clean cloth and rinse thoroughly.
                <br />
                <br />
                Da Vinci Marble will not accept claims for any reason after the material has been
                cut, modified or installed.
              </p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default MorrocanTile;
